<template>
  <article>
    <section class="info-order__container">
      <p>{{ $t("orderType") }}: {{ $t(getOrderType) }}</p>
      <p v-if="orderTypeIsToHome">{{ $t("address") }}: {{ getAddress }}</p>
      <p>{{ $t("productsQty") }}: {{ getProductQty }}</p>
      <p>
        {{ $t("amountCart") }}:
        <span>{{ getTotalPayCart }}</span>
      </p>
      <p v-if="allowTips">
        {{ $t("amountTip") }}:
        <span>+{{ getAmountTip }}</span>
      </p>
      <p class="font-weight-bold">
        {{ $t("totalToPay") }}:
        <span>{{ getTotalToPay }}</span>
      </p>
    </section>

    <SelectTip
      v-if="allowTips"
      :totalToPay="getTotalToPayNumber"
      @noTipSelected="emitFinishOrderEvent"
      class="mt-5"
    />
  </article>
</template>
<script>
import {
  orderTypeName,
  orderTypeStoreGetters,
} from "@/store/modules/orderType/constNames";
import { settingsDigitalLetterStoreGetters } from "@/store/modules/settingsDigitalLetter/constNames";
import { formatPrice } from "@/helpers";

export default {
  name: "InfoOrder",
  components: {
    SelectTip: () => import("./SelectTip.vue"),
  },
  props: {
    priceDomicileService: { type: Number },
  },
  methods: {
    formatPrice,
    emitFinishOrderEvent() {
      this.$emit("finishOrderEvent");
    },
  },
  computed: {
    getOrderToSend() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TO_SEND];
    },
    getProductQty() {
      const { products } = this.getOrderToSend;
      return products.length > 1
        ? products.reduce((qty, product) => qty + product.qty, 0)
        : products[0].qty;
    },
    getOrderType() {
      return this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
    },
    getAddress() {
      return this.$store.getters[orderTypeStoreGetters.GET_ADDRESS].street;
    },
    getTotalPayCart() {
      const amountCart = this.getTotalToPayNumber;
      return this.formatPrice(amountCart);
    },
    getTotalToPay() {
      const amountCart = this.getTotalToPayNumber;
      const amountTip = this.$store.getters["cart/getTipInfo"].amount;

      return this.formatPrice(
        this.allowTips ? amountCart + amountTip : amountCart
      );
    },
    getAmountTip() {
      const amountTip = this.$store.getters["cart/getTipInfo"].amount;

      return this.formatPrice(amountTip);
    },
    getTotalToPayNumber() {
      return this.orderTypeIsToHome
        ? +this.$store.getters["cart/totalPrice"] + this.priceDomicileService
        : +this.$store.getters["cart/totalPrice"];
    },
    orderTypeIsToHome() {
      return this.getOrderType === orderTypeName.AT_HOME;
    },
    allowTips() {
      const typeIsToLocal = this.getOrderType === orderTypeName.LOCAL;
      const allowTips =
        this.$store.getters[settingsDigitalLetterStoreGetters.GET_ALLOW_TIPS];
      return !typeIsToLocal && allowTips;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-order__container {
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;

  p {
    display: flex;
    justify-content: space-between;
  }
}
</style>
